<template>
  <div>
    <!-- Dropdown and Pagination -->
    <a-row :gutter="24" type="flex" class="mb-24">
      <a-col :span="24" class="flex">
        <!-- Rows per page dropdown -->
        <a-select
          v-model="rowsPerPage"
          style="width: 120px; margin-right: 16px;"
          @change="updateTableData"
        >
          <a-select-option :value="5">5 rows</a-select-option>
          <a-select-option :value="10">10 rows</a-select-option>
          <a-select-option :value="15">15 rows</a-select-option>
          <a-select-option :value="20">20 rows</a-select-option>
        </a-select>

        <!-- Pagination buttons -->
        <a-pagination
          :current="currentPage"
          :total="tableData.length"
          :page-size="rowsPerPage"
          @change="handlePageChange"
          style="flex-grow: 1; text-align: right;"
        />
      </a-col>
    </a-row>

    <!-- Connections Table -->
    <a-row :gutter="24" type="flex">
      <a-col :span="24" class="mb-24">
        <CardProjectTable2
          :data="paginatedTableData"
          :columns="tableColumns"
        ></CardProjectTable2>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import CardProjectTable2 from "../components/Cards/CardProjectTable2";
import axios from "axios";

export default {
  components: {
    CardProjectTable2,
  },
  data() {
    return {
      tableData: [], // Dynamic table data
      rowsPerPage: 5, // Default rows per page (set to 5 by default)
      currentPage: 1, // Current page number (to handle pagination)
      
      // Updated table column definitions
      tableColumns: [
        {
          title: "TITLE",
          dataIndex: "title",
          scopedSlots: { customRender: "title" },
          width: 200,
        },
        {
          title: "ACTION",
          dataIndex: "action",
          scopedSlots: { customRender: "action" },
        },
        {
          title: "STATUS",
          dataIndex: "status",
          scopedSlots: { customRender: "status" },
        },
        {
          title: "INTERVAL",
          dataIndex: "interval",
          scopedSlots: { customRender: "interval" },
        },
        {
          title: "",
          scopedSlots: { customRender: "editBtn" }, // Edit pencil slot
          width: 50,
        },
      ],

      // Pagination data
      paginatedTableData: [],
    };
  },
  methods: {
    // Fetch all connections for the user
    async fetchConnections() {
      const token = sessionStorage.getItem("token");
      if (!token) {
        console.error("No token found!");
        return;
      }

      try {
        // Fetch connections from the API
        const response = await axios.get(
          `https://pairplex.online/api/connections/${token}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        console.log("Raw API Response:", response.data); // Log raw response data

        // Map the API response to match table data
        this.tableData = response.data.map((connection) => {
          let logoUrl = "";
          switch (connection?.SourceAPiID) {
            case 1: // Spotify
              logoUrl = "https://static.vecteezy.com/system/resources/previews/023/986/494/non_2x/spotify-logo-spotify-logo-transparent-spotify-icon-transparent-free-free-png.png";
              break;
            case 2: // Hubspot
              logoUrl = "https://cdn4.iconfinder.com/data/icons/logos-and-brands/512/168_Hubspot_logo_logos-512.png";
              break;
            case 3: // Nike
              logoUrl = "https://purepng.com/public/uploads/large/purepng.com-nike-logologobrand-logoiconslogos-251519940082eoxxs.png";
              break;
            default:
              logoUrl = ""; // Default to no logo if SourceAPiID is not 1, 2, or 3
              break;
          }

          // Safely map the connection data with company logo
          const row = {
            key: connection?.id || "No ID", // Unique row key
            title: connection?.Title || "No Title", // Use 'Title' from API or fallback
            action: connection?.Action || "N/A", // Use 'Action' from API or fallback
            status: connection?.Status || "UNKNOWN", // Use 'Status' from API or fallback
            interval: connection?.Interval || "N/A", // Use 'Interval' from API or fallback
            sourceapiid: connection?.SourceAPiID, // Safely access SourceAPiID
            company: { name: connection?.Title || "No Title", logo: logoUrl }, // Add logo and company name
          };

          console.log("Mapped Row:", row); // Log each row
          return row;
        });

        console.log("Final Table Data:", this.tableData); // Log the final data

        // Update the paginated data initially
        this.updateTableData();
      } catch (error) {
        console.error("Error fetching connections:", error);
      }
    },

    // Update the table data when rows per page or page changes
    updateTableData() {
      const startIndex = (this.currentPage - 1) * this.rowsPerPage;
      const endIndex = startIndex + this.rowsPerPage;

      this.paginatedTableData = this.tableData.slice(startIndex, endIndex);
    },

    // Handle page change
    handlePageChange(page) {
      this.currentPage = page;
      this.updateTableData();
    },
  },

  created() {
    this.fetchConnections();
  },

  watch: {
    // Watch for changes in the rowsPerPage value
    rowsPerPage() {
      this.updateTableData();
    },
  },
};
</script>

<style scoped>
/* Add any custom styles here */
.flex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
</style>
