<template>
  <div class="form-container">
    <!-- Main Form -->
    <a-card :bordered="false" class="card-main-form" :bodyStyle="{ paddingTop: 0 }">
      <template #title>
        <!-- Back Button -->
        <div class="back-button" @click="goBack">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-arrow-return-left" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5" />
          </svg>
        </div>

        <!-- Trash Button -->
        <div class="trash-button" @click="goToDeletePage">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
  <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
</svg>
        </div>

        <div class="form-header">
          <img src="images/logos/logo-shopify.svg" alt="Shopify Icon" class="icon" />
        </div>
        <h6 style="text-align:center;"> Update connection</h6>
      </template>

      <a-form id="main-form" :form="form" class="form-content" @submit.prevent="handleUpdate">
        <a-form-item class="mb-8">
          <label><b>Title</b></label>
          <a-input
            placeholder="Enter title"
            v-decorator="['title', { rules: [{ required: true, message: 'Titel mag niet leeg zijn!' }] }]"
          />
        </a-form-item>

        <a-form-item class="mb-8">
          <label><b>Interval</b></label>
          <a-input-group compact>
            <a-input-number
              v-decorator="['interval', { 
                rules: [
                  { required: true, message: 'Interval mag niet leeg zijn!' }, 
                  { type: 'number', message: 'Interval moet een geldig nummer zijn!' }
                ] 
              }]"
              :min="1"
              placeholder="Enter interval"
              style="width: 60%"
            />
            <a-select
              v-decorator="['intervalUnit', { rules: [{ required: true, message: 'Een eenheid moet worden geselecteerd!' }] }]"
              style="width: 40%"
              placeholder="Select unit"
            >
              <a-select-option value="minutes">Minutes</a-select-option>
              <a-select-option value="hours">Hours</a-select-option>
              <a-select-option value="days">Days</a-select-option>
            </a-select>
          </a-input-group>
        </a-form-item>

        <a-form-item class="mb-8">
          <label><b>Action</b></label>
          <a-input
            placeholder="Enter action"
            v-decorator="['action', { rules: [{ required: true, message: 'Actie mag niet leeg zijn!' }] }]"
          />
        </a-form-item>

        <a-form-item class="mb-8">
          <label><b>API key 1</b></label>
          <a-input
            placeholder="Enter API key 1"
            v-decorator="['apiKey1', { rules: [{ required: true, message: 'API sleutel 1 mag niet leeg zijn!' }] }]"
          />
        </a-form-item>

        <a-form-item class="mb-8">
          <label><b>API key 2</b></label>
          <a-input
            placeholder="Enter API key 2"
            v-decorator="['apiKey2', { rules: [{ required: true, message: 'API sleutel 2 mag niet leeg zijn!' }] }]"
          />
        </a-form-item>

        <a-form-item>
          <a-button type="primary" block html-type="submit">UPDATE</a-button>
        </a-form-item>
      </a-form>
    </a-card>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      form: this.$form.createForm(this),
      id: null, // Will store the ID of the connection to update
      loggedInUserId: null, // Will store the logged-in user's ID
    };
  },
  created() {
    this.fetchExistingData();
  },
  methods: {
    fetchExistingData() {
      // Get the ID from the query parameters
      this.id = this.$route.query.id;

      if (!this.id) {
        alert("No ID provided.");
        return;
      }

      // Get the token from session storage
      const token = sessionStorage.getItem("token");
      if (!token) {
        console.error("No token found!");
        return;
      }

      // Fetch the user ID using the token
      axios
        .get(`https://pairplex.online/api/users/${token}`)
        .then((response) => {
          const loggedInUserId = response.data.UserID;
          this.loggedInUserId = loggedInUserId;

          console.log("Logged-in User ID:", loggedInUserId);

          // Now fetch the connection data
          axios
            .get(`https://pairplex.online/api/show?id=${this.id}`)
            .then((response) => {
              const data = response.data;
              console.log("Fetched data:", data);

              // Check if the connection's user ID matches the logged-in user ID
              if (data.UserID !== loggedInUserId) {
                alert("Invalid access token");
                this.$router.push("/tables"); // Redirect to tables page
                return;
              }

              // Set the interval and unit
              let interval = data.Interval;
              let intervalUnit = "minutes"; // Default to minutes

              // Convert interval to the appropriate unit
              if (interval % 1440 === 0) {
                intervalUnit = "days";
                interval = interval / 1440;
              } else if (interval % 60 === 0) {
                intervalUnit = "hours";
                interval = interval / 60;
              }

              console.log("Converted Interval:", interval, "Interval Unit:", intervalUnit);

              // Set form fields with the fetched data
              this.form.setFieldsValue({
                title: data.Title,
                interval: interval,
                intervalUnit: intervalUnit,
                action: data.Action,
                apiKey1: data.SourceAPiID,
                apiKey2: data.DestinationApiID,
              });
            })
            .catch((error) => {
              alert(`Error fetching data: ${error.message}`);
            });
        })
        .catch((error) => {
          alert(`Error fetching user data: ${error.message}`);
        });
    },

    handleUpdate() {
      this.form.validateFields((err, values) => {
        if (!err) {
          let intervalInMinutes = values.interval;
          if (values.intervalUnit === "hours") {
            intervalInMinutes *= 60;
          } else if (values.intervalUnit === "days") {
            intervalInMinutes *= 1440; // Convert to minutes
          }

          const payload = {
            id: this.id,
            userid: this.loggedInUserId, // Use the logged-in user ID
            title: values.title,
            interval: intervalInMinutes,
            action: values.action,
            status: "active",
            sourceapiid: values.apiKey1,
            destinationapiid: values.apiKey2,
          };

          axios
            .put(`https://pairplex.online/api/update/${this.id}`, payload)
            .then((response) => {
              alert(`Success! The connection has been updated. Details: ${JSON.stringify(response.data, null, 2)}`);
            })
            .catch((error) => {
              alert(`Error updating data: ${error.message}`);
            });
        } else {
          alert("There are errors in the form. Please check the fields and try again.");
        }
      });
    },

    goBack() {
      this.$router.push('/tables');
    },

    goToDeletePage() {
      this.$router.push(`/delete?id=${this.id}`);
    }
  },
};
</script>

<style lang="scss">
.form-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  padding: 20px;
  position: relative;
}

.back-button {
  position: absolute;
  top: 10px;
  left: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #1890ff;
}

.trash-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #ff4d4f;
}

.card-main-form {
  width: 400px;
  position: relative;
}

.form-header {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.icon {
  width: 50px;
  height: 50px;
}

.form-content {
  padding: 10px 20px;
}

.mb-8 {
  margin-bottom: 8px;
}
</style>
