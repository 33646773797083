<template>
  <div>
    <h1>Tickets van Rick - UserID =  {{ customUserId }}</h1>
    <ul v-if="tickets.length > 0">
      <li v-for="ticket in tickets" :key="ticket.id">
        <strong>{{ ticket.properties.subject }}</strong> - {{ ticket.properties.content }}
      </li>
    </ul>
    <p v-else>Geen tickets gevonden voor deze gebruiker.</p>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      tickets: [], // Hier worden de tickets opgeslagen
      customUserId: "2", // Het custom_user_id waar je naar zoekt
    };
  },
  methods: {
    async fetchTickets() {
      try {
        const response = await axios.get(
            `https://pairplex.online/api/tickets?custom_user_id=${this.customUserId}`
        );
        console.log("API Response:", response.data); // Debugging om te controleren of de response goed is
        this.tickets = response.data || []; // Zorg ervoor dat de respons goed wordt toegewezen
        console.log("Tickets:", this.tickets); // Debugging om te controleren welke tickets worden opgeslagen
      } catch (error) {
        console.error("Error fetching tickets:", error.response ? error.response.data : error);
      }
    },
  },
  mounted() {
    this.fetchTickets(); // Haal de tickets op wanneer de component is geladen
  },
};
</script>
