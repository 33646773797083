<template>
  <div class="delete-card">
    <!-- Terug-knop -->
    <div class="back-button" @click="goBack">
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-arrow-return-left" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5"/>
      </svg>
    </div>

    <!-- Afbeelding van Adobe XD logo -->
    <div class="icon">
      <img src="https://upload.wikimedia.org/wikipedia/commons/c/c2/Adobe_XD_CC_icon.svg" alt="Adobe XD logo" />
    </div>

    <!-- Title label, shows the actual title from API -->
    <div v-if="title">
      <h3>{{ title }}</h3> <!-- Title fetched from the backend -->
    </div>

    <!-- Titel input veld -->
    <div class="input-group">
      <label for="title">Type the connection name to confirm:</label>
      <input type="text" id="title" v-model="titleveld" placeholder="Enter title" />
    </div>

    <!-- Verwijder-knop -->
    <button :class="['delete-button', { 'enabled': confirmDelete && isTitleMatched }]" @click="deleteItem" :disabled="!confirmDelete || !isTitleMatched">
      DELETE PERMANENTLY
    </button>

    <!-- Toggle voor bevestiging -->
    <div class="confirmation">
      <input type="checkbox" id="confirm" v-model="confirmDelete" :disabled="!isTitleMatched" />
      <label for="confirm">Yes, I want to permanently delete this connection.</label>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      title: "", // Default leeg
      titleveld: "", // User's input for confirmation
      confirmDelete: false,
      id: null, // To store the ID passed from the URL
      loggedInUserId: null, // Logged-in user ID to check authorization
    };
  },
  computed: {
    isTitleMatched() {
      // Check if the user's input matches the title fetched from the backend
      return this.titleveld.trim() === this.title.trim();
    },
  },
  mounted() {
    this.id = this.$route.query.id; // Get the ID from the URL query parameter
    if (this.id) {
      this.fetchLoggedInUserData(); // Eerst de ingelogde user data ophalen
    } else {
      alert("No ID provided.");
      this.goBack(); // Stuur terug naar de /tables-pagina
    }
  },
  methods: {
    // Fetch connection data from the backend
    fetchConnectionData() {
      axios
        .get(`https://pairplex.online/api/show?id=${this.id}`)
        .then((response) => {
          const data = response.data;
          this.title = data.Title; // Set the title field to the name of the loaded API

          // Controleer of de UserID overeenkomt
          if (data.UserID !== this.loggedInUserId) {
            alert("Invalid access token. You are not authorized to view or delete this connection.");
            this.goBack(); // Redirect naar de /tables-pagina
          }
        })
        .catch((error) => {
          alert(`Error fetching connection data: ${error.message}`);
          this.goBack(); // Redirect naar de /tables-pagina bij fout
        });
    },

    // Fetch the logged-in user's ID using the token
    fetchLoggedInUserData() {
      const token = sessionStorage.getItem("token"); // Get the token from session storage
      if (!token) {
        alert("No token found!");
        this.goBack(); // Stuur terug naar de /tables-pagina
        return;
      }

      axios
        .get(`https://pairplex.online/api/users/${token}`)
        .then((response) => {
          this.loggedInUserId = response.data.UserID;
          console.log("Logged-in User ID:", this.loggedInUserId);

          // Zodra de user ID is opgehaald, fetch de verbinding
          this.fetchConnectionData();
        })
        .catch((error) => {
          alert(`Error fetching user data: ${error.message}`);
          this.goBack(); // Redirect naar de /tables-pagina bij fout
        });
    },

    // Method to go back to the previous page
    goBack() {
      this.$router.push({ name: "Tables" }); // Pas dit aan afhankelijk van je router structuur
    },

    // Method to handle the delete operation
    deleteItem() {
      if (this.confirmDelete && this.isTitleMatched) {
        // Check if the logged-in user has permission to delete the connection
        axios
          .get(`https://pairplex.online/api/show?id=${this.id}`)
          .then((response) => {
            const data = response.data;
            if (data.UserID !== this.loggedInUserId) {
              alert("Invalid access token. You are not authorized to delete this connection.");
              this.goBack(); // Redirect back to the tables page
              return;
            }

            // Proceed with deletion if the user is authorized
            axios
              .delete(`https://pairplex.online/api/delete/${this.id}`)
              .then((response) => {
                alert("Connection deleted successfully");
                this.goBack();
              })
              .catch((error) => {
                alert(`Error deleting connection: ${error.message}`);
              });
          })
          .catch((error) => {
            alert(`Error fetching connection data: ${error.message}`);
          });
      }
    },
  },
};
</script>


<style scoped>
.delete-card {
  max-width: 400px;
  background-color: #f7f8fa;
  padding: 20px;
  border-radius: 12px;
  text-align: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin: 10% auto;
  position: relative; /* Ensure the card has relative positioning for absolute elements inside */
}

.back-button {
  position: absolute;
  top: 10px; /* Adjust the top positioning */
  left: 20px; /* Adjust the left positioning */
  color: #1890ff;
  cursor: pointer;
}

.icon {
  margin: 30px 0 20px 0; /* Adjust margin for better spacing between the back button and logo */
}

.icon img {
  width: 80px;
  height: 80px;
}

.input-group {
  margin: 20px 0;
}

.input-group label {
  display: flex;
  font-weight: bold;
  margin-bottom: 5px;
}

.input-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #d1d5db;
  border-radius: 10px;
  outline: none;
}

.delete-button {
  width: 100%;
  padding: 15px;
  border: none;
  border-radius: 8px;
  background-color: #e0e7ff;
  color: #9ca3af;
  font-weight: bold;
  cursor: not-allowed;
  margin-top: 20px;
  transition: background-color 0.3s, color 0.3s;
}

.delete-button.enabled {
  background-color: #ef4444;
  color: white;
  cursor: pointer;
}

.confirmation {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #4b5563;
  margin-top: 15px;
}

.confirmation input {
  margin-right: 10px;
}

</style>
