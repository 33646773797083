<template>

  <!-- Projects Table Column -->
  <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
    <template #title>
      <a-row type="flex" align="middle">
        <a-col :span="24" :md="12">
          <h5 class="font-semibold m-0">Connections Overview</h5>
        </a-col>
        <a-col :span="24" :md="12" style="display: flex; align-items: center; justify-content: flex-end">
          <a-radio-group v-model="projectHeaderBtns" size="small">
            <a-radio-button value="all">ALL</a-radio-button>
            <a-radio-button value="online">ONLINE</a-radio-button>
            <a-radio-button value="offline">OFFLINE</a-radio-button>
          </a-radio-group>
        </a-col>
      </a-row>
    </template>
    <a-table :columns="columns" :data-source="data" :pagination="false">

      <template slot="status" slot-scope="status">
  <span :class="status === 'ONLINE' ? 'online-status' : 'offline-status'">
    {{ status }}
  </span>
      </template>

      <a-space slot="members" slot-scope="members" :size="-12" class="avatar-chips">
        <template v-for="member in members">
          <a-avatar :key="member" size="small" :src="member"/>
        </template>
      </a-space>

      <template slot="company" slot-scope="company">
        <h6 class="m-0">
          <img :src="company.logo" width="25" style="margin-right: 10px;">
          {{ company.name }}
        </h6>
      </template>

      <template slot="completion" slot-scope="completion">
        <div class="progress-right">
          <div class="text-right text-sm font-semibold text-muted pr-15">
            {{ completion.value || completion.value == 0 ? completion.value : completion }}%
          </div>
          <a-progress class="m-0" :percent="completion.value || completion.value == 0 ? completion.value : completion"
                      :show-info="false" size="small" :status="completion.status ? completion.status : 'normal'"/>
        </div>
      </template>

      <template slot="editBtn" slot-scope="row">
    <a-button type="link" :data-id="row.key" @click="editProject(row)">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="fill-gray-7"
              d="M13.5858 3.58579C14.3668 2.80474 15.6332 2.80474 16.4142 3.58579C17.1953 4.36683 17.1953 5.63316 16.4142 6.41421L15.6213 7.20711L12.7929 4.37868L13.5858 3.58579Z"/>
        <path class="fill-gray-7" d="M11.3787 5.79289L3 14.1716V17H5.82842L14.2071 8.62132L11.3787 5.79289Z"/>
      </svg>
    </a-button>
  </template>

    </a-table>

    <div class="table-upload-btn" @click="createProject">
      <a-button type="dashed" block>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"/>
        </svg>
        Create New Connection
      </a-button>
    </div>
  </a-card>
  <!-- / Projects Table Column -->

</template>

<script>

export default ({
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      projectHeaderBtns: 'all', // Default filter set to "all"
    };
  },
  methods: {
    createProject() {
        this.$router.push('/create');
    },
    editProject(row) {
    // Pass the id of the selected row to the update page
    this.$router.push({ path: '/update', query: { id: row.key } });
  }
  },
  computed: {
    // Computed property to filter data based on the selected button
    filteredData() {
      if (this.projectHeaderBtns === 'all') {
        return this.data;
      }
      return this.data.filter(row => row.status === this.projectHeaderBtns.toUpperCase());
    },
  },
});

</script>
