<template>
	<div>
	  <!-- Counter Widgets -->
	  <a-row :gutter="24">
		<a-col
		  :span="24"
		  :lg="12"
		  :xl="6"
		  class="mb-24"
		  v-for="(stat, index) in stats"
		  :key="index"
		>
		  <!-- Widget 1 Card -->
		  <WidgetCounter
			:title="stat.title"
			:value="stat.value"
			:prefix="stat.prefix"
			:suffix="stat.suffix"
			:icon="stat.icon"
			:status="stat.status"
		  ></WidgetCounter>
		  <!-- / Widget 1 Card -->
		</a-col>
	  </a-row>
	  <!-- / Counter Widgets -->
  
	  <!-- Charts -->
	  <a-row :gutter="24" type="flex" align="stretch">
		<a-col :span="24" :lg="12" class="mb-24">
		  <!-- Active Users Card -->
		  <CardBarChart></CardBarChart>
		  <!-- / Active Users Card -->
		</a-col>
		<a-col :span="24" :lg="12" class="mb-24">
		  <!-- Sales Overview Card -->
		  <CardLineChart></CardLineChart>
		  <!-- / Sales Overview Card -->
		</a-col>
	  </a-row>
	  <!-- / Charts -->
  
	  <!-- Table & Timeline -->
	  <a-row :gutter="24" type="flex" align="stretch">
		<!-- Table -->
		<a-col :span="24" :lg="16" class="mb-24">
		  <!-- Connections Table -->
		  <CardProjectTable2 :data="paginatedTableData" :columns="tableColumns"></CardProjectTable2>
		  <!-- / Connections Table -->
		</a-col>
  
		<!-- Timeline -->
		<a-col :span="24" :lg="8" class="mb-24">
		  <!-- Orders History Timeline Card -->
		  <CardOrderHistory></CardOrderHistory>
		  <!-- / Orders History Timeline Card -->
		</a-col>
		<!-- / Timeline -->
	  </a-row>
	  <!-- / Table & Timeline -->
	</div>
  </template>
  
  <script>
  import CardBarChart from "../components/Cards/CardBarChart";
  import CardLineChart from "../components/Cards/CardLineChart";
  import WidgetCounter from "../components/Widgets/WidgetCounter";
  import CardProjectTable2 from "../components/Cards/CardProjectTable2";
  import CardOrderHistory from "../components/Cards/CardOrderHistory";
  import axios from "axios";
  
  export default {
	components: {
	  CardBarChart,
	  CardLineChart,
	  WidgetCounter,
	  CardProjectTable2,
	  CardOrderHistory,
	},
	data() {
	  return {
		stats: [
		  {
			title: "Connections",
			value: "3/5",
			prefix: "",
			suffix: "",
			icon: `<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-plug-fill" viewBox="0 0 16 16"><path d="M6 0a.5.5 0 0 1 .5.5V3h3V.5a.5.5 0 0 1 1 0V3h1a.5.5 0 0 1 .5.5v3A3.5 3.5 0 0 1 8.5 10c-.002.434-.01.845-.04 1.22-.041.514-.126 1.003-.317 1.424a2.08 2.08 0 0 1-.97 1.028C6.725 13.9 6.169 14 5.5 14c-.998 0-1.61.33-1.974.718A1.92 1.92 0 0 0 3 16H2c0-.616.232-1.367.797-1.968C3.374 13.42 4.261 13 5.5 13c.581 0 .962-.088 1.218-.219.241-.123.4-.3.514-.55.121-.266.193-.621.23-1.09.027-.34.035-.718.037-1.141A3.5 3.5 0 0 1 4 6.5v-3a.5.5 0 0 1 .5-.5h1V.5A.5.5 0 0 1 6 0"/></svg>`,
		  },
		  {
			title: "Calls today",
			value: 50,
			icon: `<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-lightning-charge-fill" viewBox="0 0 16 16"><path d="M11.251.068a.5.5 0 0 1 .227.58L9.677 6.5H13a.5.5 0 0 1 .364.843l-8 8.5a.5.5 0 0 1-.842-.49L6.323 9.5H3a.5.5 0 0 1-.364-.843l8-8.5a.5.5 0 0 1 .615-.09z"/></svg>`,
		  },
		  {
			title: "Errors",
			value: 1,
			status: "danger",
			icon: `<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-exclamation-triangle-fill" viewBox="0 0 16 16"><path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5m.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2"/></svg>`,
		  },
		  {
			title: "Remaining calls",
			value: "950/1000",
			icon: `<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-lightning-charge-fill" viewBox="0 0 16 16"><path d="M11.251.068a.5.5 0 0 1 .227.58L9.677 6.5H13a.5.5 0 0 1 .364.843l-8 8.5a.5.5 0 0 1-.842-.49L6.323 9.5H3a.5.5 0 0 1-.364-.843l8-8.5a.5.5 0 0 1 .615-.09z"/></svg>`,
		  },
		],
  
		tableData: [],
		tableColumns: [
		  {
			title: "TITLE",
			dataIndex: "title",
			scopedSlots: { customRender: "title" },
			width: 200,
		  },
		  {
			title: "ACTION",
			dataIndex: "action",
			scopedSlots: { customRender: "action" },
		  },
		  {
			title: "STATUS",
			dataIndex: "status",
			scopedSlots: { customRender: "status" },
		  },
		  {
			title: "INTERVAL",
			dataIndex: "interval",
			scopedSlots: { customRender: "interval" },
		  },
		  {
			title: "",
			scopedSlots: { customRender: "editBtn" },
			width: 50,
		  },
		],
		paginatedTableData: [],
	  };
	},
	methods: {
	  async fetchConnections() {
		const token = sessionStorage.getItem("token");
		if (!token) return;
  
		try {
		  const response = await axios.get(
			`https://pairplex.online/api/connections/${token}`,
			{
			  headers: {
				Authorization: `Bearer ${token}`,
			  },
			}
		  );
  
		  this.tableData = response.data.map((connection) => ({
			key: connection.id || "No ID",
			title: connection.Title || "No Title",
			action: connection.Action || "N/A",
			status: connection.Status || "UNKNOWN",
			interval: connection.Interval || "N/A",
		  }));
  
		  // After fetching, update the paginated data
		  this.updateTableData();
		} catch (error) {
		  console.error("Error fetching connections:", error);
		}
	  },
  
	  updateTableData() {
		const maxRows = 4;
		this.paginatedTableData = this.tableData.slice(0, maxRows); // Only the first 5 rows
	  },
	},
	created() {
	  this.fetchConnections();
	},
  };
  </script>
  
  <style lang="scss">
  </style>
  