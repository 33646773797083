<template>
  <div class="form-container">
    <!-- Main Form -->
    <a-card :bordered="false" class="card-main-form" :bodyStyle="{paddingTop: 0}">
      <template #title>
        <!-- Back Button -->
        <div class="back-button" @click="goBack">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-arrow-return-left" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5"/>
          </svg>
        </div>
        <div class="form-header">
          <img src="images/logos/logo-shopify.svg" alt="Shopify Icon" class="icon" />
        </div>
        <h6 style="text-align:center;"> New connection</h6>
      </template>

      <a-form id="main-form" :form="form" class="form-content" @submit.prevent="handleSubmit">
        <a-form-item class="mb-8">
          <label><b>Title</b></label>
          <a-input placeholder="Enter title" v-decorator="['title', { rules: [{ required: true, message: 'Please input the title!' }] }]" />
        </a-form-item>

        <a-form-item class="mb-8">
          <label><b>Interval</b></label>
          <a-input-group compact>
            <a-input-number
              v-decorator="['interval', { rules: [{ required: true, message: 'Please input the interval!' }] }]"
              min="1"
              placeholder="Enter interval"
              style="width: 60%"
            />
            <a-select
              v-decorator="['intervalUnit', { rules: [{ required: true, message: 'Please select the unit!' }] }]"
              style="width: 40%"
              placeholder="Select unit"
            >
              <a-select-option value="minutes">Minutes</a-select-option>
              <a-select-option value="hours">Hours</a-select-option>
              <a-select-option value="days">Days</a-select-option>
            </a-select>
          </a-input-group>
        </a-form-item>

        <a-form-item class="mb-8">
          <label><b>Action</b></label>
          <a-input placeholder="Enter action" v-decorator="['action', { rules: [{ required: true, message: 'Please input the action!' }] }]" />
        </a-form-item>

        <a-form-item class="mb-8">
          <label><b>API key 1</b></label>
          <a-input placeholder="Enter API key 1" v-decorator="['apiKey1', { rules: [{ required: true, message: 'Please input the API key 1!' }] }]" />
        </a-form-item>

        <a-form-item class="mb-8">
          <label><b>API key 2</b></label>
          <a-input placeholder="Enter API key 2" v-decorator="['apiKey2', { rules: [{ required: true, message: 'Please input the API key 2!' }] }]" />
        </a-form-item>

        <a-form-item>
          <a-button type="primary" block html-type="submit">CREATE</a-button>
        </a-form-item>
      </a-form>
    </a-card>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      form: this.$form.createForm(this),
    };
  },
  methods: {
    handleSubmit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          // Convert interval to minutes
          let intervalInMinutes = values.interval;
          if (values.intervalUnit === "hours") {
            intervalInMinutes *= 60;
          } else if (values.intervalUnit === "days") {
            intervalInMinutes *= 1440; // 24 hours * 60 minutes
          }

          const token = sessionStorage.getItem("token"); // Haal userId op uit sessionStorage
          if (!token) {
            console.error("Geen token gevonden in sessionStorage!");
            return;
          }

          // Prepare payload
          const payload = {
            userid: token, // Hardcoded User ID
            title: values.title,
            interval: intervalInMinutes,
            action: values.action,
            status: "active", // Hardcoded status
            sourceapiid: values.apiKey1,
            destinationapiid: values.apiKey2,
          };

          // Send data to the API
          axios
            .post("https://pairplex.online/api/insert", payload)
            .then((response) => {
              this.$router.push('/tables');
            })
            .catch((error) => {
              alert(`Error: ${error.message}`);
              //console.log(payload);
            });
        } else {
          alert("Please correct the errors before submitting.");
        }
      });
    },
    goBack() {
      //alert("Back button clicked");
      this.$router.push('/tables');
    },
  },
};
</script>

<style lang="scss">
.form-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  padding: 20px;
  position: relative;
}

.back-button {
  position: absolute;
  top: 10px;
  left: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #1890ff;
}

.card-main-form {
  width: 400px;
  position: relative;
}

.form-header {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.icon {
  width: 50px;
  height: 50px;
}

.form-content {
  padding: 10px 20px;
}

.mb-8 {
  margin-bottom: 8px;
}
</style>