<template>

	<!-- Profile Information Card -->
	<a-card :bordered="false" class="header-solid h-full card-profile-information" :bodyStyle="{paddingTop: 0, paddingBottom: '16px' }" :headStyle="{paddingRight: 0,}">
		<template #title>
			<h6 class="font-semibold m-0">Profile Information</h6>
		</template>
		<a-button type="link" slot="extra">
			<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path class="fill-muted" d="M13.5858 3.58579C14.3668 2.80474 15.6332 2.80474 16.4142 3.58579C17.1953 4.36683 17.1953 5.63316 16.4142 6.41421L15.6213 7.20711L12.7929 4.37868L13.5858 3.58579Z" fill="#111827"/>
				<path class="fill-muted" d="M11.3787 5.79289L3 14.1716V17H5.82842L14.2071 8.62132L11.3787 5.79289Z" fill="#111827"/>
			</svg>
		</a-button>
		<p class="text-dark"> *In onderhoud* </p>
		<hr class="my-25">
		<a-descriptions :column="1">
			<a-descriptions-item label="Full Name">
				{{ user.FirstName }} {{ user.LastName }}
			</a-descriptions-item>
			<a-descriptions-item label="Mobile">

			</a-descriptions-item>
			<a-descriptions-item label="Email">
				{{ user.Email }}
			</a-descriptions-item>
			<a-descriptions-item label="Location">
				NL
			</a-descriptions-item>

		</a-descriptions>
	</a-card>
	<!-- / Profile Information Card -->

</template>

<script>
import axios from 'axios';
	export default ({
		data() {
			return {
				user: null, // Gebruikersgegevens worden hier opgeslagen
			}
		},
		methods: {
			createConnection() {
				this.$router.push('/create');
			},
			async fetchUser() {
				const token = sessionStorage.getItem("token"); // Haal userId op uit sessionStorage
				if (!token) {
					// console.error("Geen token gevonden in sessionStorage!");
					return;
				}

				try {
					const response = await axios.get(`https://pairplex.online/api/users/${token}`);
					this.user = response.data; // Sla de gebruikersgegevens op
					// console.log("User gegevens:" + user)
					// console.log(token)
				} catch (error) {
					// console.error("Fout bij het ophalen van gebruikersgegevens:", error + user);
				}
			},
		},
		created() {
			this.fetchUser(); // Haal de gebruikersgegevens op bij het laden van de pagina
		},
	})

</script>